<template>
  <div class="">
    <div class="Ptit">
      <strong class="tit">提现</strong>
    </div>
    <div>
      <template v-if="ztype === 0">
        <!-- 请先实名认证或认证企业 -->
        <div class="tishiSh">
          <el-result
            icon="warning"
            title="无操作权限"
            sub-title="请先完成 实名认证 或 认证所属企业"
          >
          </el-result>
        </div>
      </template>
      <template v-else>
        <template v-if="sjh === false">
          <div class="sjyz726">
            <!-- 手机号验证 -->
            <div class="formBx2">
              <br /><br />
              <h3 class="TxC">验证手机号</h3>
              <div class="Mt16">
                <el-input
                  v-model="sjyz.phone"
                  placeholder="请输入手机号"
                  class="w-50 m-2"
                >
                  <!-- <template #prefix>
                    <el-icon class="el-input__icon"
                      ><svg-icon icon="mobile-phone"
                    /></el-icon>
                  </template> -->
                </el-input>
              </div>

              <div class="Mt12 fxBox">
                <div class="fx1">
                  <el-input
                    v-model="sjyz.code"
                    :maxlength="4"
                    :minlength="4"
                    @input="codeInput"
                    placeholder="请输入验证码"
                  />
                </div>
                <div class="Ml12">
                  <el-button
                    @click="phoneCodeFun"
                    type="primary"
                    plain
                    :disabled="yamDisabled"
                    >{{ yamTex }}</el-button
                  >
                </div>
              </div>
              <div class="Mt12 fxBox">
                <el-button
                  type="primary"
                  :disabled="sjhButton"
                  style="width:100%"
                  @click="phoneNextFun"
                  >下一步</el-button
                >
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <template v-if="ztype === 1">
            <div class="tixianMain">
              <template v-if="bankCardLis.length === 0"></template>
              <template v-else>
                <div class="bankCardLis">
                  <h4>历史提现帐户</h4>
                  <dl
                    v-for="ikm in bankCardLis"
                    :key="ikm.pID"
                    @click="actCardfun(ikm)"
                    :class="actCard === ikm.pID ? 'act' : ''"
                  >
                    <dd>{{ ikm.name }}</dd>
                    <dt>{{ ikm.cardID }}</dt>
                  </dl>
                </div>
              </template>
              <div class="fx1">
                <br /><br />
                <h4 class="FsHs TxC">个人用户，请填写姓名相同银行卡卡号</h4>
                <br />
                <div class="formBx">
                  <el-form
                    ref="ruleFormRef"
                    :model="ruleForm"
                    :rules="rules"
                    label-width="100px"
                    class="usHome-ruleForm"
                    :size="formSize"
                  >
                    <el-form-item label="真实姓名" prop="name">
                      <el-input v-model="ruleForm.name" />
                    </el-form-item>
                    <el-form-item label="银行卡号" prop="cardID">
                      <el-input v-model="ruleForm.cardID" />
                    </el-form-item>
                    <el-form-item label="提现积分额" prop="numerical">
                      <el-input
                        :maxlength="6"
                        :minlength="1"
                        v-model="ruleForm.numerical"
                      />
                    </el-form-item>

                    <el-form-item>
                      <el-button
                        type="primary"
                        style="width:100%"
                        @click="submitForm(ruleFormRef)"
                        >提交</el-button
                      >
                    </el-form-item>
                  </el-form>
                </div>
              </div>
            </div>
          </template>
          <template v-if="ztype === 2">
            <br /><br />
            <h4 class="FsHs TxC">企业用户 请填写对企业公账户</h4>
            <br />
            <div class="formBx">
              <el-form
                ref="ruleFormRef"
                :model="ruleForm"
                :rules="rules"
                label-width="100px"
                class="usHome-ruleForm"
                :size="formSize"
              >
                <el-form-item label="企业名称" prop="name">
                  <el-input v-model="ruleForm.name" />
                </el-form-item>
                <el-form-item label="对公账户" prop="cardID">
                  <el-input v-model="ruleForm.cardID" />
                </el-form-item>
                <el-form-item label="金额" prop="numerical">
                  <el-input
                    :maxlength="6"
                    :minlength="1"
                    v-model="ruleForm.numerical"
                  />
                </el-form-item>

                <el-form-item>
                  <el-button
                    type="primary"
                    style="width:100%"
                    @click="submitForm(ruleFormRef)"
                    >提交</el-button
                  >
                </el-form-item>
              </el-form>
            </div>
          </template>
          <template v-if="ztype === 5">
            <el-result
              icon="success"
              title="提交成功"
              sub-title="请耐心等待财务人员审核"
            >
            </el-result>
          </template>
        </template>
      </template>
    </div>
  </div>
</template>
<script setup>
import { ref } from 'vue'
import { gettixianphone, gettixianjijiao, gettixiancardlist } from '@/api/user'
import { sign, getItem, toUsId } from '@/utils/storage'
import { ElMessage } from 'element-plus'
// import tools from '@/utils/tools'
// import { useRouter } from 'vue-router'
// const router = useRouter()
const sjh = ref(false) // 手机验证
const sjhButton = ref(true) // 手机验证下步按钮状态
const fhcode = ref('') // 实际验证码
const ztype = ref(0)
const us = getItem('userinfo')

const ruleFormRef = ref()
const ruleForm = ref({
  pID: '',
  name: '',
  numerical: '',
  state: 0,
  cardID: '',
  tx: 0 // 个人0，企业1
})
ruleForm.value.tuID = toUsId(getItem('token'))
const rules = {
  cardID: [
    { required: true, message: '银行卡不能为空', trigger: 'blur' },
    { min: 12, max: 40, message: '12-40个字符', trigger: 'blur' }
  ],
  name: [{ required: true, message: '姓名不能为空', trigger: 'blur' }],
  numerical: [
    { required: true, message: '提现金额不能为空', trigger: 'blur' },
    {
      required: true,
      pattern: /^[1-9]\d*$/,
      message: '金额为大于0的整数',
      trigger: 'blur'
    }
  ]
}
if (us.certification === 3) {
  ztype.value = 1
  ruleForm.value.tx = 0
} else if (us.comSH === 0 || us.comSH === 2) {
  ztype.value = 2
  ruleForm.value.tx = 1
} else {
}
const sjyz = ref({
  phone: '',
  code: ''
})

// 银行卡列表
const bankCardLis = ref([])
const actCard = ref(0)
const getusBankCard = async () => {
  var icar = await gettixiancardlist(
    sign({ sjs: Math.ceil(Math.random() * 10) })
  )
  bankCardLis.value = icar.list
}
getusBankCard()
// 选中卡
const actCardfun = ica => {
  actCard.value = ica.pID
  ruleForm.value.cardID = ica.cardID
  ruleForm.value.name = ica.name
}

/** -----  验证手机号 */
const yamTex = ref('发送验证码')
const yamDisabled = ref(false)
const phoneCodeFun = async () => {
  if (sjyz.value.phone.length === 11) {
    var res = await gettixianphone(
      sign({ mobile: sjyz.value.phone, sjs: Math.ceil(Math.random() * 10) })
    )

    ruleForm.value.pID = res.sid
    fhcode.value = '' + res.pay

    var time = 60
    const set = setInterval(function() {
      // 执行倒计时方法，一秒钟执行一次
      --time
      yamTex.value = `重新发送(${time}s)`
      yamDisabled.value = true
    }, 1000) // 此方法后面跟的是毫秒级单位

    // 60s之后执行的方法
    setTimeout(function() {
      yamTex.value = '获取验证码'
      yamDisabled.value = false
      clearInterval(set) // 同时停止上面重复方法的执行
    }, 60000)

    // sjh.value = true
  } else {
    ElMessage.error('请输入有效手机号')
  }
}
const codeInput = v => {
  if (v.length === 4) {
    sjhButton.value = false
  } else {
    sjhButton.value = true
  }
}
const phoneNextFun = () => {
  if (fhcode.value !== sjyz.value.code) {
    ElMessage.error('验证码不正确')
  } else {
    sjh.value = true
  }
}

// 提交
const submitForm = async formEl => {
  if (!formEl) return
  if (us.integral < ruleForm.value.numerical) {
    ElMessage.error('提现积分不能大于您当前积分')
    return false
  }
  await formEl.validate((valid, fields) => {
    if (valid) {
      postCertificationFun(ruleForm.value)
    } else {
      console.log('提交失败', fields)
    }
  })
}
const postCertificationFun = async data => {
  var revv = await gettixianjijiao(sign(data))
  if (revv === 'ok') {
    ztype.value = 5
  }
}

// const goPage = ss => {
//   router.push(ss)
// }
</script>
<style lang="scss" scoped>
.formBx2 {
  margin: auto;
  max-width: 400px;
}

.tishiSh {
  padding: 20px;
  font-size: 1.2rem;

  text-align: center;
}
</style>
